<template>
  <v-card>
    <v-row no-gutters>
      <v-col class="scroller" :style="scrollerStyle">
        <v-card class="card">
          <v-card-text>
            <h2>
              Overblik
            </h2>
            <TcoCalculationSummaryTable :questions :showTags="true" :hoveredTag />
          </v-card-text>
        </v-card>
        <v-card class="card">
          <v-card-text>
            <h2>Evalueringsteknisk pris (beregning)</h2>
            <div v-for="i in [0, 1, 2, 3, 4, 5]" :key="i">
              <v-row v-if="getTable(cat1Hours, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat1Hours, i)"
                    :headers="getHeaders(cat1Hours, i)"
                    :hoveredTag
                    :tags="[]"
                  />
                </v-col>
              </v-row>
              <v-row v-if="getTable(cat2Hours, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat2Hours, i)"
                    :headers="getHeaders(cat2Hours, i)"
                    :hoveredTag
                    :tags="[]"
                  />
                </v-col>
              </v-row>
              <v-row v-if="getTable(cat1Ongoing, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat1Ongoing, i)"
                    :headers="getHeaders(cat1Ongoing, i)"
                    :hoveredTag
                    :tags="[
                      { row: 0, cell: 3, value: 'G' },
                      { row: 1, cell: 3, value: 'H' },
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row v-if="getTable(cat2Ongoing, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat2Ongoing, i)"
                    :headers="getHeaders(cat2Ongoing, i)"
                    :hoveredTag
                    :tags="[
                      { row: 0, cell: 3, value: 'M' },
                      { row: 1, cell: 3, value: 'N' },
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row v-if="getTable(cat2Fixed, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat2Fixed, i)"
                    :headers="getHeaders(cat2Fixed, i)"
                    :hoveredTag
                    :tags="[]"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <TcoCalculation
                  :table="getTable(totalSection, 0)"
                  :headers="getHeaders(totalSection, 0)"
                  :hoveredTag
                  :tags="[]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card color="#fff9ef" class="fill-height" flat>
          <v-card-text ref="description">
            <TcoCalculationDescription @updateHoveredTag="updateHoveredTag" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { Question, TcoCalculationTable } from '@dims/components';
import TcoCalculationDescription from './TcoCalculationDescription.vue';
import TcoCalculationSummaryTable from './TcoCalculationSummaryTable.vue';
import TcoCalculation from './TcoCalculation.vue';

const { questions, tables } = defineProps<{
  questions: Question[],
  tables: Record<string, TcoCalculationTable> }>();
const scrollerStyle = ref({ maxHeight: undefined as string | undefined });
const hoveredTag = ref<string>();

const cat1Hours = 'Cat1Hours';
const cat1Ongoing = 'Cat1Ongoing';
const cat2Hours = 'Cat2Hours';
const cat2Ongoing = 'Cat2Ongoing';
const cat2Fixed = 'Cat2Fixed';
const totalSection = 'Total';

function updateHoveredTag(tag: string) {
  hoveredTag.value = tag;
}

const description = ref<Element>();

onMounted(() => {
  // Set height of left column to height of description
  // We wait for next tick because mounted() does not guarantee that the DOM has finished rendering
  void nextTick(() => {
    if (description.value) {
      const height = description.value.clientHeight;
      scrollerStyle.value.maxHeight = `${height}px`;
    }
  });
});

const headers: Record<string, {value: string, tag: string}[]> = {
  Cat1Hours:
    [
      {
        value: 'Timekøb',
        tag: '',
      },
      {
        value: 'Antal timer',
        tag: 'A',
      },
      {
        value: 'Pris',
        tag: 'B',
      },
      {
        value: 'Trappetrin',
        tag: 'C',
      },
      {
        value: '% stigning når trappetrin opnås',
        tag: 'D',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Cat2Hours:
    [
      {
        value: 'Timekøb',
        tag: '',
      },
      {
        value: 'Antal timer',
        tag: 'I',
      },
      {
        value: 'Pris',
        tag: 'J',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Cat1Ongoing:
    [
      {
        value: 'Løbende Ydelser',
        tag: '',
      },
      {
        value: 'Antal måneder',
        tag: 'F',
      },
      {
        value: 'Antal timer',
        tag: 'E',
      },
      {
        value: 'Pris',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Cat2Ongoing:
    [
      {
        value: 'Løbende Ydelser',
        tag: '',
      },
      {
        value: 'Antal måneder',
        tag: 'L',
      },
      {
        value: 'Antal timer',
        tag: 'K',
      },
      {
        value: 'Pris',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Cat2Fixed:
    [
      {
        value: 'Fastprisaftale',
        tag: '',
      },
      {
        value: 'Antal timer',
        tag: 'O',
      },
      {
        value: 'Pris',
        tag: 'P',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Total:
    [
      {
        value: 'Total',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
};

function getTable(section: string, optionNumber: number) {
  const tableName = optionNumber > 0 ? `${section}Option${optionNumber}` : section;
  const table = tables[tableName];
  if (!table) {
    return { rows: [] };
  }
  return table;
}

function getHeaders(section: string, optionNumber: number) {
  const header = headers[section];
  if (!header) {
    return [];
  }
  const optionHeader = [...header];
  if (optionNumber > 0 && optionHeader[0]) {
    optionHeader[0] = { value: optionHeader[0].value, tag: optionHeader[0].tag };
    optionHeader[0].value = `<strong>Option ${optionNumber}:</strong><br />${optionHeader[0].value}`;
  }
  return optionHeader;
}

</script>

<style scoped>
.card {
  margin: 2em;
  background: rgb(var(--v-theme-canvas-base)) !important;
}

h2 {
  color: rgb(var(--v-theme-primary));
  margin-bottom: 1em;
  margin-top: 0;
  text-transform: uppercase;
}

.scroller {
  overflow-y: scroll !important;
}
</style>
